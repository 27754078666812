module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"movendos-homepage","short_name":"movendos","start_url":"/","background_color":"#ffffff","theme_color":"#229899","display":"minimal-ui","icon":"src/images/logoIcon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"250ce6d592e874bd1a9c29802f4ed038"},
    },{
      plugin: require('../node_modules/gatsby-background-image/gatsby-browser.js'),
      options: {"plugins":[],"specialChars":"/:"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://4dbe31a083bf41b7a550b33780169dbc@o392235.ingest.sentry.io/5239465","environment":"production","enabled":"https://4dbe31a083bf41b7a550b33780169dbc@o392235.ingest.sentry.io/5239465"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
